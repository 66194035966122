import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import * as footerLogo from '../../../../../../theme/src/images/patronscan-logo-bw.svg';

const SiteFooter = () => {
  return (
    <footer>
      <div className="bg-grey-darkest p-6 lg:py-12 flex flex-col lg:flex-row items-center font-heading font-hairline text-white text-center">
        <div className="w-full lg:w-1/3 lg:p-8">
          <AniLink fade to="/">
            <img
              src={footerLogo}
              className="m-auto xl:m-0 max-w-footer-logo opacity-100 hover:opacity-75 transition-opacity"
              alt="Patronscan Black and White Logo"
            />
          </AniLink>
        </div>
        <div className="w-full w-1/2 lg:w-1/3 lg:border-l lg:inline-flex lg:items-center lg:text-left lg:pl-8 lg:pr-8 lg:pb-8 lg:my-0 my-8">
          <div className=" mt-6 lg:mt-0 lg:w-1/2">
            <p className="text-lg text-heading font-medium">Contact Us</p>
            <a
              className="block hover:text-grey-dark transition-color text-1xl lg:text-base"
              href="tel:+4401702549100"
            >
              +44 (0)1702 549100
            </a>
            <a
              className="block hover:text-grey-dark transition-color text-1xl lg:text-base"
              href="mailto:sales@patronscan.uk"
            >
              sales@patronscan.uk
            </a>
            <div className="mt-2">
              <a
                className="transition-color hover:text-grey-dark text-3xl"
                aria-label="twitter"
                rel="noopener"
                href="https://twitter.com/patronscan"
                target="_blank"
              >
                <FontAwesomeIcon icon={faTwitterSquare} />
              </a>
            </div>
          </div>
          <div className="w-full mt-6 lg:mt-0 lg:w-1/2">
            <p className="text-lg text-heading font-medium">Regions</p>
            <a
              className="block hover:text-grey-dark transition-color text-1xl lg:text-base"
              href="https://patronscan.com"
            >
              North America
            </a>
            <a
              className="block hover:text-grey-dark transition-color text-1xl lg:text-base"
              href="https://patronscan.com.au"
            >
              Australia
            </a>
            <AniLink
              to="/"
              className="block hover:text-grey-dark transition-color text-1xl lg:text-base"
            >
              United Kingdom
            </AniLink>
          </div>
        </div>
        <div className="w-full lg:w-1/3 lg:text-left lg:border-l lg:pl-8 lg:pr-8 lg:my-0">
          <AniLink
            fade
            className="block hover:text-grey-dark transition-color text-1xl lg:text-base"
            to="/"
          >
            Home
          </AniLink>
          <AniLink
            fade
            className="block hover:text-grey-dark transition-color text-1xl lg:text-base"
            to="/industries"
          >
            Who We Help
          </AniLink>
          <AniLink
            fade
            className="block hover:text-grey-dark transition-color text-1xl lg:text-base"
            to="/advantages"
          >
            Advantages
          </AniLink>
          <AniLink
            fade
            className="block hover:text-grey-dark transition-color text-1xl lg:text-base"
            to="/our-story"
          >
            Our Story
          </AniLink>
          <AniLink
            fade
            className="block hover:text-grey-dark transition-color text-1xl lg:text-base"
            to="/privacy"
          >
            Committed to Privacy
          </AniLink>
        </div>
      </div>
      <div className="bg-black text-center text-white font-hairline font-heading p-4">
        <p>
          © {new Date().getFullYear()} Patronscan. All Rights Reserved. |{' '}
          <AniLink fade to="/privacy">
            Privacy Policy
          </AniLink>
        </p>
      </div>
    </footer>
  );
};

export default SiteFooter;
