import React from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink"

interface MenuProps {
    closeMenu?: () => void;
}
export const Menu: React.FunctionComponent<MenuProps & React.HTMLAttributes<HTMLDivElement>> = (props) => {
    return (
        <nav className={props.className}>
            <ul className="xl:inline-flex center xl:pr-8">
                <li onClick={props.closeMenu} className="mb-4 xl:my-6">
                    <AniLink className="text-grey-darker font-light text-xl hover:text-blue-lighter tracking-widest transition-color xl:text-base xl:mx-4" fade to="/industries">Who We Help</AniLink>
                </li>
                <li onClick={props.closeMenu} className="my-4 xl:my-6">
                    <AniLink className="text-grey-darker font-light text-xl hover:text-blue-lighter tracking-widest transition-color xl:text-base xl:mx-4" fade to="/advantages">Our Advantage</AniLink>
                </li>
                <li onClick={props.closeMenu} className="my-4 xl:my-6">
                    <AniLink className="text-grey-darker font-light text-xl hover:text-blue-lighter tracking-widest transition-color xl:text-base xl:mx-4" fade to="/our-story">Our Story</AniLink>
                </li>
                <li onClick={props.closeMenu} className="my-4 xl:my-6">
                    <AniLink className="text-grey-darker font-light text-xl hover:text-blue-lighter tracking-widest transition-color xl:text-base xl:mx-4" fade to="/privacy">Commited to Privacy</AniLink>
                </li>
            </ul>
            <a href="tel:+4401702549100" className="hidden xl:inline-block text-blue-darker hover:text-blue-lightest transition-color pl-8 pr-20 font-light">+44 (0)1702 549100</a>
            <AniLink onClick={props.closeMenu} fade to="/get-pricing"><button className="mt-4 bg-gradient-r-button cursor-pointer px-4 text-heading font-hairline py-2 leading-none w-full text-white text-xl rounded-lg font-medium xl:font-light xl:text-sm xl:w-auto xl:text-base xl:mt-0 hover:shadow-inner-lg transition-shadow">Get Pricing</button></AniLink>
        </nav>
    );
}