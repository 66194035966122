// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---theme-src-pages-404-tsx": () => import("../../../theme/src/pages/404.tsx" /* webpackChunkName: "component---theme-src-pages-404-tsx" */),
  "component---theme-src-pages-accelerate-reopening-of-hospitality-industry-tsx": () => import("../../../theme/src/pages/accelerate-reopening-of-hospitality-industry.tsx" /* webpackChunkName: "component---theme-src-pages-accelerate-reopening-of-hospitality-industry-tsx" */),
  "component---src-pages-advantages-tsx": () => import("../src/pages/advantages.tsx" /* webpackChunkName: "component---src-pages-advantages-tsx" */),
  "component---theme-src-pages-anti-fraud-tsx": () => import("../../../theme/src/pages/anti-fraud.tsx" /* webpackChunkName: "component---theme-src-pages-anti-fraud-tsx" */),
  "component---theme-src-pages-app-tsx": () => import("../../../theme/src/pages/app.tsx" /* webpackChunkName: "component---theme-src-pages-app-tsx" */),
  "component---theme-src-pages-app-mobile-tsx": () => import("../../../theme/src/pages/app/mobile.tsx" /* webpackChunkName: "component---theme-src-pages-app-mobile-tsx" */),
  "component---theme-src-pages-app-psmobile-tsx": () => import("../../../theme/src/pages/app/psmobile.tsx" /* webpackChunkName: "component---theme-src-pages-app-psmobile-tsx" */),
  "component---theme-src-pages-bar-and-club-stats-tsx": () => import("../../../theme/src/pages/bar-and-club-stats.tsx" /* webpackChunkName: "component---theme-src-pages-bar-and-club-stats-tsx" */),
  "component---theme-src-pages-developer-tsx": () => import("../../../theme/src/pages/developer.tsx" /* webpackChunkName: "component---theme-src-pages-developer-tsx" */),
  "component---theme-src-pages-expert-thanks-tsx": () => import("../../../theme/src/pages/expert-thanks.tsx" /* webpackChunkName: "component---theme-src-pages-expert-thanks-tsx" */),
  "component---theme-src-pages-fake-id-scanner-tsx": () => import("../../../theme/src/pages/fake-id-scanner.tsx" /* webpackChunkName: "component---theme-src-pages-fake-id-scanner-tsx" */),
  "component---theme-src-pages-get-id-scanner-app-tsx": () => import("../../../theme/src/pages/get-id-scanner-app.tsx" /* webpackChunkName: "component---theme-src-pages-get-id-scanner-app-tsx" */),
  "component---theme-src-pages-get-pricing-tsx": () => import("../../../theme/src/pages/get-pricing.tsx" /* webpackChunkName: "component---theme-src-pages-get-pricing-tsx" */),
  "component---src-pages-id-scanner-for-bars-tsx": () => import("../src/pages/id-scanner-for-bars.tsx" /* webpackChunkName: "component---src-pages-id-scanner-for-bars-tsx" */),
  "component---theme-src-pages-id-scanning-blog-home-tsx": () => import("../../../theme/src/pages/id-scanning-blog-home.tsx" /* webpackChunkName: "component---theme-src-pages-id-scanning-blog-home-tsx" */),
  "component---theme-src-pages-id-scanning-blog-do-fake-ids-pass-scanners-tsx": () => import("../../../theme/src/pages/id-scanning-blog/do-fake-ids-pass-scanners.tsx" /* webpackChunkName: "component---theme-src-pages-id-scanning-blog-do-fake-ids-pass-scanners-tsx" */),
  "component---theme-src-pages-id-scanning-blog-how-accurate-is-facial-recognition-tsx": () => import("../../../theme/src/pages/id-scanning-blog/how-accurate-is-facial-recognition.tsx" /* webpackChunkName: "component---theme-src-pages-id-scanning-blog-how-accurate-is-facial-recognition-tsx" */),
  "component---theme-src-pages-id-scanning-blog-how-to-spot-a-fake-id-tsx": () => import("../../../theme/src/pages/id-scanning-blog/how-to-spot-a-fake-id.tsx" /* webpackChunkName: "component---theme-src-pages-id-scanning-blog-how-to-spot-a-fake-id-tsx" */),
  "component---theme-src-pages-id-scanning-blog-serving-alcohol-partnership-tsx": () => import("../../../theme/src/pages/id-scanning-blog/serving-alcohol-partnership.tsx" /* webpackChunkName: "component---theme-src-pages-id-scanning-blog-serving-alcohol-partnership-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---theme-src-pages-industries-tsx": () => import("../../../theme/src/pages/industries.tsx" /* webpackChunkName: "component---theme-src-pages-industries-tsx" */),
  "component---theme-src-pages-industry-dispensaries-tsx": () => import("../../../theme/src/pages/industry/dispensaries.tsx" /* webpackChunkName: "component---theme-src-pages-industry-dispensaries-tsx" */),
  "component---theme-src-pages-industry-property-management-tsx": () => import("../../../theme/src/pages/industry/property-management.tsx" /* webpackChunkName: "component---theme-src-pages-industry-property-management-tsx" */),
  "component---theme-src-pages-industry-retail-vape-testimonial-tsx": () => import("../../../theme/src/pages/industry/retail-vape/testimonial.tsx" /* webpackChunkName: "component---theme-src-pages-industry-retail-vape-testimonial-tsx" */),
  "component---theme-src-pages-industry-retail-vapor-tsx": () => import("../../../theme/src/pages/industry/retail-vapor.tsx" /* webpackChunkName: "component---theme-src-pages-industry-retail-vapor-tsx" */),
  "component---theme-src-pages-industry-retail-tsx": () => import("../../../theme/src/pages/industry/retail.tsx" /* webpackChunkName: "component---theme-src-pages-industry-retail-tsx" */),
  "component---theme-src-pages-industry-telecom-tsx": () => import("../../../theme/src/pages/industry/telecom.tsx" /* webpackChunkName: "component---theme-src-pages-industry-telecom-tsx" */),
  "component---theme-src-pages-industry-vehicle-rental-tsx": () => import("../../../theme/src/pages/industry/vehicle-rental.tsx" /* webpackChunkName: "component---theme-src-pages-industry-vehicle-rental-tsx" */),
  "component---theme-src-pages-job-thanks-tsx": () => import("../../../theme/src/pages/job-thanks.tsx" /* webpackChunkName: "component---theme-src-pages-job-thanks-tsx" */),
  "component---theme-src-pages-our-story-tsx": () => import("../../../theme/src/pages/our-story.tsx" /* webpackChunkName: "component---theme-src-pages-our-story-tsx" */),
  "component---theme-src-pages-pricing-thanks-tsx": () => import("../../../theme/src/pages/pricing-thanks.tsx" /* webpackChunkName: "component---theme-src-pages-pricing-thanks-tsx" */),
  "component---theme-src-pages-privacy-entertainment-venues-tsx": () => import("../../../theme/src/pages/privacy-entertainment-venues.tsx" /* webpackChunkName: "component---theme-src-pages-privacy-entertainment-venues-tsx" */),
  "component---theme-src-pages-privacy-guest-register-tsx": () => import("../../../theme/src/pages/privacy-guest-register.tsx" /* webpackChunkName: "component---theme-src-pages-privacy-guest-register-tsx" */),
  "component---theme-src-pages-privacy-online-age-verification-tsx": () => import("../../../theme/src/pages/privacy-online-age-verification.tsx" /* webpackChunkName: "component---theme-src-pages-privacy-online-age-verification-tsx" */),
  "component---theme-src-pages-privacy-tsx": () => import("../../../theme/src/pages/privacy.tsx" /* webpackChunkName: "component---theme-src-pages-privacy-tsx" */),
  "component---theme-src-pages-register-app-tsx": () => import("../../../theme/src/pages/register-app.tsx" /* webpackChunkName: "component---theme-src-pages-register-app-tsx" */),
  "component---theme-src-pages-safe-orlando-id-scanners-tsx": () => import("../../../theme/src/pages/safe-orlando-id-scanners.tsx" /* webpackChunkName: "component---theme-src-pages-safe-orlando-id-scanners-tsx" */),
  "component---theme-src-pages-support-tsx": () => import("../../../theme/src/pages/support.tsx" /* webpackChunkName: "component---theme-src-pages-support-tsx" */),
  "component---theme-src-pages-talk-to-an-expert-tsx": () => import("../../../theme/src/pages/talk-to-an-expert.tsx" /* webpackChunkName: "component---theme-src-pages-talk-to-an-expert-tsx" */),
  "component---theme-src-pages-vaccine-passport-tsx": () => import("../../../theme/src/pages/vaccine-passport.tsx" /* webpackChunkName: "component---theme-src-pages-vaccine-passport-tsx" */),
  "component---theme-src-pages-work-with-us-tsx": () => import("../../../theme/src/pages/work-with-us.tsx" /* webpackChunkName: "component---theme-src-pages-work-with-us-tsx" */),
  "component---src-pages-why-patronscan-tsx": () => import("../src/pages/why-patronscan.tsx" /* webpackChunkName: "component---src-pages-why-patronscan-tsx" */)
}

