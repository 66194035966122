import React, { FC } from 'react';
import Header from './header';
import Footer from './footer';
import './layout.css';

interface LayoutProps {}

export const Layout: FC<LayoutProps> = (props) => {

  return (
    <div>
      <Header />
      <main className="font-body bg-white font-hairline">{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
